<template>
  <div>
    <section
      class="tn-comp-pin tn-comp-style-pin"
      tn-link="compAttr.link || comp.link"
      tn-sound="compAttr.sound"
      ng-style="compAttr.style | optimizeImageType"
      tn-pre-load-image="compAttr.style.backgroundImage"
      style="background-color: rgb(185, 217, 235)"
    >
      <div
        class="tn-layer-slot tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-state-active"
        tn-cell-type="group"
        tn-cell="layers"
        tn-link="cell.link"
        ng-style="cell.style | optimizeImageType"
        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
        tn-animate-on-self="true"
        tn-sound="cell.sound"
        ng-class="{ 'group-empty': !(cellGroupItems.length) }"
        opera-tn-ra-cell="box.cubes:0.pages:0.layers"
        style=""
      >
        <!-- ngRepeat: chd in cellGroupItems track by $index -->
        <div
          class="tn-layer ng-scope tn-from-house-reader_booklet-cp tn-comp-anim-pin tn-comp-inst tn-layer-inst tn-comp tn-in-cell-state-active"
          tn-comp-role="layer"
          tn-bind-comp-tpl-id="reader_booklet-cp:sys/ly-fs"
          ng-repeat="chd in cellGroupItems track by $index"
          tn-comp="chd"
          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
          tn-uuid=""
          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
          tn-comp-index="0"
          opera-tn-ra-comp="box.cubes:0.pages:0.layers:0"
          style=""
        >
          <section
            class="tn-comp-pin tn-comp-style-pin"
            tn-link="compAttr.link || comp.link"
            tn-sound="compAttr.sound"
            ng-style="compAttr.style | optimizeImageType"
            tn-pre-load-image="compAttr.style.backgroundImage"
          >
            <div
              class="tn-comp-slot tn-cell tn-cell-group tn-child-position-absolute tn-state-active ng-scope"
              tn-cell-type="group"
              tn-link="cell.link"
              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
              tn-animate-on-self="true"
              tn-ra-wrapper-cell=""
              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps"
            >
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:0"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:0.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:1"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:1.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      text-align: justify;
                      font-size: 225%;
                      color: rgb(0, 133, 202);
                      letter-spacing: 2px;
                      text-shadow: rgb(255, 255, 255) 4px 0px;
                    "
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.txt1"
                  >
                    <p style="text-align: center"><strong>美敦力</strong></p>
                    <p style="text-align: center">
                      <strong>创新加速器简介</strong>
                    </p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    text-align: center;
                    justify-content: center;
                    margin: 30px 0% 0px;
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                      height: auto;
                      border-width: 0px;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="
                        position: static;
                        transform: perspective(0px);
                        transform-style: flat;
                      "
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 20px 0% 0px;
                          transform: translate3d(20px, 0px, 0px) rotateY(180deg);
                        "
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            box-shadow: rgb(0, 0, 0) 0px 0px 0px;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col1:0.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/3c3a6e6f4547db0f7deb2dd500648f5b_sz-17139.webp"
                            style="vertical-align: middle; max-width: 100%"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 30%;
                      align-self: flex-start;
                      height: auto;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="text-align: center; margin: 0px 0%"
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            width: 70%;
                            height: auto;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col2:0.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/7c434c2878db0153278420d69a72ae50_sz-7248.webp"
                            style="
                              vertical-align: middle;
                              max-width: 100%;
                              width: 100%;
                            "
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.txt1"
                  >
                    <p>&#8203;</p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="text-align: center"
                >
                  <div
                    style="
                      display: inline-block;
                      width: 95%;
                      vertical-align: top;
                      border-width: 0px;
                      border-radius: 15px;
                      border-style: solid;
                      border-color: rgb(255, 255, 255);
                      background-color: rgb(255, 255, 255);
                      height: auto;
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:0.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 93%;
                            vertical-align: top;
                            height: auto;
                            border-left: 3px solid rgb(255, 255, 255);
                            border-top-style: none;
                            border-bottom-style: none;
                            border-right-style: none;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  font-size: 87.5%;
                                  line-height: 1.8;
                                  letter-spacing: 2px;
                                  padding: 0px 10px;
                                  font-family: PingFangSC-light;
                                  color: rgb(83, 86, 90);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:1.col1:0.txt1"
                              >
                                <p>
                                  美敦力创新加速器是美敦力设立在中国上海的一个创新孵化加速平台，其目的是促进更多医疗技术在中国市场转化为有价值的医疗产品和服务，成为可供患者选择的、高效的健康解决方案，同时为中国推进建设全球科技创新中心打造可持续的发展力。<br />
                                </p>
                                <p>
                                  美敦力创新加速器由美敦力大中华区建立，获得了临港集团和闵行区政府的支持。它毗邻美敦力中国研发中心，座落于上海临港浦江国际科技城，已投入使用的一期设施占地1000平方米，有可供10家医疗技术创业企业自用的研发及行政基础设施模块，还能够利用美敦力的研发网络中的强大资源。
                                </p>
                                <p>美敦力创新加速器于2019年3月25日正式启用。</p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:2.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col2:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    display: flex;
                    flex-flow: row nowrap;
                    margin: 0px 0%;
                    transform: translate3d(35px, 0px, 0px);
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      width: 80%;
                      vertical-align: top;
                      flex: 0 0 auto;
                      align-self: flex-start;
                      height: auto;
                      padding: 0px;
                      border-width: 0px;
                      border-radius: 10px;
                      border-style: none;
                      border-color: rgb(62, 62, 62);
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          margin: 0px 0%;
                          display: flex;
                          flex-flow: row nowrap;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            min-width: 10%;
                            max-width: 100%;
                            flex: 0 0 auto;
                            height: auto;
                            background-color: rgb(0, 133, 202);
                            border-width: 0px;
                            border-radius: 0px 0px 10px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="text-align: center"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  height: 30px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-frozen ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style=""
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        font-size: 93.75%;
                                        color: rgb(255, 255, 255);
                                        padding: 0px 10px;
                                        line-height: 2;
                                        letter-spacing: 3px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col1:0.col1:0.txt1"
                                    >
                                      <p style=""><b>入驻企业实验室</b></p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            flex: 100 100 0%;
                            height: auto;
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                            line-height: 0;
                            letter-spacing: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                  line-height: 0.5;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col2:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col2:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.col2:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg?_tn_usage_=tn-image"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          display: flex;
                          flex-flow: row nowrap;
                          margin: 0px 0%;
                          isolation: isolate;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: auto;
                            vertical-align: top;
                            flex: 0 0 0%;
                            height: auto;
                            line-height: 0.5;
                            align-self: flex-start;
                            padding: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                                margin: 0px 0%;
                                transform: translate3d(-1px, 0px, 0px);
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:1.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:1.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:1.col1:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg?_tn_usage_=tn-image"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="text-align: center; margin: -46px 0% 0px"
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            border-width: 0px;
                            border-radius: 18px 6px 6px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            overflow: hidden;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:2.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/85ba298db872c8080b37bffeee433df0-sz_423224.webp"
                            style="vertical-align: middle; max-width: 100%"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:9"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:9.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:9.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:9.col1:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:9.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:9.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:9.col2:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    display: flex;
                    flex-flow: row nowrap;
                    margin: 0px 0%;
                    transform: translate3d(35px, 0px, 0px);
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      width: 80%;
                      vertical-align: top;
                      flex: 0 0 auto;
                      align-self: flex-start;
                      height: auto;
                      padding: 0px;
                      border-width: 0px;
                      border-radius: 10px;
                      border-style: none;
                      border-color: rgb(62, 62, 62);
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          margin: 0px 0%;
                          display: flex;
                          flex-flow: row nowrap;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            min-width: 10%;
                            max-width: 100%;
                            flex: 0 0 auto;
                            height: auto;
                            background-color: rgb(0, 133, 202);
                            border-width: 0px;
                            border-radius: 0px 0px 10px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="text-align: center"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  height: 30px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-frozen ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style=""
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        font-size: 93.75%;
                                        color: rgb(255, 255, 255);
                                        padding: 0px 10px;
                                        line-height: 2;
                                        letter-spacing: 3px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0.col1:0.txt1"
                                    >
                                      <p style=""><b>入驻企业办公室</b></p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            flex: 100 100 0%;
                            height: auto;
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                            line-height: 0;
                            letter-spacing: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                  line-height: 0.5;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col2:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col2:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col2:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          display: flex;
                          flex-flow: row nowrap;
                          margin: 0px 0%;
                          isolation: isolate;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: auto;
                            vertical-align: top;
                            flex: 0 0 0%;
                            height: auto;
                            line-height: 0.5;
                            align-self: flex-start;
                            padding: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                                margin: 0px 0%;
                                transform: translate3d(-1px, 0px, 0px);
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:1.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:1.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:1.col1:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg?_tn_usage_=tn-image"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="text-align: center; margin: -46px 0% 0px"
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            border-width: 0px;
                            border-radius: 18px 6px 6px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            overflow: hidden;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:2.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/493a505a27983ba25a81e2a7d2798dc2-sz_432364.jpg"
                            style="vertical-align: middle; max-width: 100%"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    display: flex;
                    flex-flow: row nowrap;
                    margin: 0px 0%;
                    transform: translate3d(35px, 0px, 0px);
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      width: 80%;
                      vertical-align: top;
                      flex: 0 0 auto;
                      align-self: flex-start;
                      height: auto;
                      padding: 0px;
                      border-width: 0px;
                      border-radius: 10px;
                      border-style: none;
                      border-color: rgb(62, 62, 62);
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          margin: 0px 0%;
                          display: flex;
                          flex-flow: row nowrap;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            min-width: 10%;
                            max-width: 100%;
                            flex: 0 0 auto;
                            height: auto;
                            background-color: rgb(0, 133, 202);
                            border-width: 0px;
                            border-radius: 0px 0px 10px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="text-align: center"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  height: 30px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-frozen ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style=""
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        font-size: 93.75%;
                                        color: rgb(255, 255, 255);
                                        padding: 0px 10px;
                                        line-height: 2;
                                        letter-spacing: 3px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col1:0.col1:0.txt1"
                                    >
                                      <p style=""><b>入驻企业会议室</b></p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            flex: 100 100 0%;
                            height: auto;
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                            line-height: 0;
                            letter-spacing: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                  line-height: 0.5;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col2:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col2:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:0.col2:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg?_tn_usage_=tn-image"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          display: flex;
                          flex-flow: row nowrap;
                          margin: 0px 0%;
                          isolation: isolate;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: auto;
                            vertical-align: top;
                            flex: 0 0 0%;
                            height: auto;
                            line-height: 0.5;
                            align-self: flex-start;
                            padding: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                                margin: 0px 0%;
                                transform: translate3d(-1px, 0px, 0px);
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:1.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:1.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:1.col1:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg?_tn_usage_=tn-image"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="text-align: center; margin: -46px 0% 0px"
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            border-width: 0px;
                            border-radius: 18px 6px 6px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            overflow: hidden;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:2.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/c823de74c0fdf48311e30ff794bb684b-sz_378660.webp"
                            style="vertical-align: middle; max-width: 100%"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 50%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin: 0px 0%;
                          justify-content: center;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 10px;
                            height: 40px;
                            vertical-align: top;
                            overflow: hidden;
                            border-left: 3px double rgb(255, 255, 255);
                            border-bottom-left-radius: 0px;
                          "
                          tn-cell-type="group"
                          tn-child-orientation="fixed"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2:0.col1"
                        ></div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    display: flex;
                    flex-flow: row nowrap;
                    margin: 0px 0%;
                    transform: translate3d(35px, 0px, 0px);
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      width: 80%;
                      vertical-align: top;
                      flex: 0 0 auto;
                      align-self: flex-start;
                      height: auto;
                      padding: 0px;
                      border-width: 0px;
                      border-radius: 10px;
                      border-style: none;
                      border-color: rgb(62, 62, 62);
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          margin: 0px 0%;
                          display: flex;
                          flex-flow: row nowrap;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            min-width: 10%;
                            max-width: 100%;
                            flex: 0 0 auto;
                            height: auto;
                            background-color: rgb(0, 133, 202);
                            border-width: 0px;
                            border-radius: 0px 0px 10px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="text-align: center"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  height: 30px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-frozen ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style=""
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        font-size: 93.75%;
                                        color: rgb(255, 255, 255);
                                        padding: 0px 10px;
                                        line-height: 2;
                                        letter-spacing: 3px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col1:0.col1:0.txt1"
                                    >
                                      <p style=""><b>入驻企业办公室</b></p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            flex: 100 100 0%;
                            height: auto;
                            align-self: flex-start;
                            z-index: 2;
                            margin: 0px;
                            line-height: 0;
                            letter-spacing: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                  line-height: 0.5;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col2:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col2:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.col2:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg?_tn_usage_=tn-image"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          display: flex;
                          flex-flow: row nowrap;
                          margin: 0px 0%;
                          isolation: isolate;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: auto;
                            vertical-align: top;
                            flex: 0 0 0%;
                            height: auto;
                            line-height: 0.5;
                            align-self: flex-start;
                            padding: 0px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: left;
                                justify-content: flex-start;
                                margin: 0px 0%;
                                transform: translate3d(-1px, 0px, 0px);
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: top;
                                  overflow: hidden;
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:1.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:1.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="text-align: center; margin: 0px 0%"
                                  >
                                    <div
                                      tn-cell-type="image"
                                      style="
                                        max-width: 100%;
                                        vertical-align: middle;
                                        display: inline-block;
                                        line-height: 0;
                                      "
                                      class="tn-cell tn-cell-image tn-state-active ng-scope"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      title=""
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:1.col1:0.col1:0.img1"
                                      tn-image="cell"
                                      tn-image-usage="foreground"
                                      opera-tn-image="cell"
                                      opera-tn-image-usage="foreground"
                                    >
                                      <svg
                                        class="tn-image-presenter svg-non-expand"
                                        preserveAspectRatio="none"
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 150 150"
                                        style="
                                          vertical-align: middle;
                                          max-width: 100%;
                                        "
                                      >
                                        <image
                                          class="tn-clip-content"
                                          xlink:href="//cdn.yueshare.cn/mlab/b88997bd0fa647ece2161257777dfbb8-sz_330.svg?_tn_usage_=tn-image"
                                          width="100%"
                                          height="100%"
                                        ></image>
                                      </svg>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="text-align: center; margin: -46px 0% 0px"
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            border-width: 0px;
                            border-radius: 18px 6px 6px;
                            border-style: none;
                            border-color: rgb(62, 62, 62);
                            overflow: hidden;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:2.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/7417523628f35ac84f0271bf7599a1b3-sz_290403.webp"
                            style="vertical-align: middle; max-width: 100%"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    text-align: left;
                    justify-content: flex-start;
                    margin: 10px 0%;
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      width: auto;
                      vertical-align: top;
                      min-width: 10%;
                      max-width: 100%;
                      height: auto;
                      box-shadow: rgb(0, 0, 0) 0px 0px 0px;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="text-align: center"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: auto;
                            vertical-align: top;
                            min-width: 10%;
                            max-width: 100%;
                            height: auto;
                            padding: 0px 10px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  color: rgb(0, 133, 202);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.col1:0.txt1"
                              >
                                <p>
                                  <strong
                                    ><span style="font-size: 125%"
                                      >&nbsp;
                                      &nbsp;以“创新、共享、加速”为定位</span
                                    ></strong
                                  >
                                </p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: left;
                          justify-content: flex-start;
                          margin: 0px 0%;
                          font-size: 0%;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 100%;
                            vertical-align: top;
                            height: auto;
                            line-height: 0;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="
                              position: static;
                              transform: scale(0.8);
                              transform-origin: center center;
                              margin: 0px -33px;
                              max-width: unset !important;
                            "
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                margin: 0px 0%;
                                text-align: right;
                                justify-content: flex-end;
                                transform: translate3d(30px, 0px, 0px);
                              "
                            >
                              <div
                                tn-cell-type="shape"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-shape tn-state-active ng-scope"
                                style="
                                  background-color: rgb(0, 133, 202);
                                  height: 2px;
                                "
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:1.col1:0.line1"
                              ></div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:17"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: left; color: rgb(0, 133, 202)"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:17.txt1"
                  >
                    <p>
                      <strong
                        >&nbsp; &nbsp; &nbsp; 协同企业资源与政府服务</strong
                      >
                    </p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:18"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:19"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="text-align: center"
                >
                  <div
                    style="
                      display: inline-block;
                      width: 95%;
                      vertical-align: top;
                      border-width: 0px;
                      border-radius: 15px;
                      border-style: solid;
                      border-color: rgb(255, 255, 255);
                      background-color: rgb(255, 255, 255);
                      height: auto;
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:19.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:19.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:19.col1:0.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:19.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 93%;
                            vertical-align: top;
                            height: auto;
                            border-left: 3px solid rgb(255, 255, 255);
                            border-top-style: none;
                            border-bottom-style: none;
                            border-right-style: none;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:19.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:19.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  font-size: 87.5%;
                                  line-height: 1.8;
                                  letter-spacing: 2px;
                                  padding: 0px 10px;
                                  font-family: PingFangSC-light;
                                  color: rgb(83, 86, 90);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:19.col1:1.col1:0.txt1"
                              >
                                <p>
                                  美敦力创新加速器致力于赋能拥有产品转化潜力的早期阶段医疗技术创业公司，帮助实现从实验室到市场的创新成熟与成果转化，通过服务患者和提升行业能力来创造价值。
                                </p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:19.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:19.col1:2.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 20%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin-top: 10px;
                          margin-bottom: 10px;
                          transform: translate3d(10px, 0px, 0px);
                        "
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            width: 95%;
                            height: auto;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col1:0.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/2b1e8a61e909b680d8dd6d6644f57396-sz_11404.webp"
                            style="
                              vertical-align: middle;
                              max-width: 100%;
                              width: 100%;
                            "
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 80%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          margin: 50px 0% 0px;
                          transform: translate3d(30px, 0px, 0px);
                        "
                      >
                        <div
                          style="
                            text-align: justify;
                            font-size: 125%;
                            color: rgb(0, 133, 202);
                          "
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2:0.txt1"
                        >
                          <p><strong>创新</strong></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:22"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="text-align: center"
                >
                  <div
                    style="
                      display: inline-block;
                      width: 95%;
                      vertical-align: top;
                      border-width: 0px;
                      border-radius: 15px;
                      border-style: solid;
                      border-color: rgb(255, 255, 255);
                      background-color: rgb(255, 255, 255);
                      height: auto;
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:22.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:22.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:22.col1:0.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:22.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 93%;
                            vertical-align: top;
                            height: auto;
                            border-left: 3px solid rgb(255, 255, 255);
                            border-top-style: none;
                            border-bottom-style: none;
                            border-right-style: none;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:22.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:22.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  font-size: 87.5%;
                                  line-height: 1.8;
                                  letter-spacing: 2px;
                                  padding: 0px 10px;
                                  font-family: PingFangSC-light;
                                  color: rgb(83, 86, 90);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:22.col1:1.col1:0.txt1"
                              >
                                <p>
                                  <span style="font-size: 128.571%"
                                    ><strong
                                      ><span style=""
                                        >发挥创新专长：</span
                                      ></strong
                                    ></span
                                  >
                                </p>
                                <p>
                                  <span style=""
                                    >背靠美敦力公司近70年来在70多种疾病治疗领域的创新资产，以及包括中国在内的150个国家和地区的市场经验，美敦力创新加速器将致力于发掘有意义的医疗科技创新项目，促进项目从成长走向成功，从本土走向全球，实现价值提升，造福全球患者。</span
                                  >
                                </p>
                                <p><br /></p>
                                <p>
                                  <span style=""
                                    ><strong
                                      ><span style="font-size: 128.571%"
                                        >关注创新前沿</span
                                      ></strong
                                    >：</span
                                  >
                                </p>
                                <p>
                                  <span style=""
                                    >美敦力创新加速器关注能够改善患者生命的医疗科技创新前沿，包括人工智能、机器人、生物工程、新材料等广泛领域；优先支持在国际范围内具有突破性、颠覆性潜力，并能够填补国内市场未满足需求的本土或国际医疗创新项目。</span
                                  >
                                </p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:22.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:22.col1:2.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 20%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin-top: 10px;
                          margin-bottom: 10px;
                          transform: translate3d(10px, 0px, 0px);
                        "
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                            width: 95%;
                            height: auto;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col1:0.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/c0831eb242949da6ffc0c1e45028a549-sz_20442.webp"
                            style="
                              vertical-align: middle;
                              max-width: 100%;
                              width: 100%;
                            "
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 80%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          margin: 50px 0% 0px;
                          transform: translate3d(30px, 0px, 0px);
                        "
                      >
                        <div
                          style="
                            text-align: justify;
                            font-size: 125%;
                            color: rgb(0, 133, 202);
                          "
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2:0.txt1"
                        >
                          <p><strong>共享</strong></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:25"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="text-align: center"
                >
                  <div
                    style="
                      display: inline-block;
                      width: 95%;
                      vertical-align: top;
                      border-width: 0px;
                      border-radius: 15px;
                      border-style: solid;
                      border-color: rgb(255, 255, 255);
                      background-color: rgb(255, 255, 255);
                      height: auto;
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:25.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:25.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:25.col1:0.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:25.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 93%;
                            vertical-align: top;
                            height: auto;
                            border-left: 3px solid rgb(255, 255, 255);
                            border-top-style: none;
                            border-bottom-style: none;
                            border-right-style: none;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:25.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:25.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  font-size: 87.5%;
                                  line-height: 1.8;
                                  letter-spacing: 2px;
                                  padding: 0px 10px;
                                  font-family: PingFangSC-light;
                                  color: rgb(0, 75, 135);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:25.col1:1.col1:0.txt1"
                              >
                                <p>
                                  <span style="color: rgb(83, 86, 90)"
                                    ><strong
                                      ><span style="font-size: 128.571%"
                                        >共享资源：</span
                                      ></strong
                                    ></span
                                  >
                                </p>
                                <p>
                                  <span style="color: rgb(83, 86, 90)"
                                    >美敦力创新加速器的合作伙伴能够有机会使用加速器的办公与实验空间，并有机会享受美敦力中国研发中心实验设备的测试服务与专家咨询服务。除早期研发支持外，美敦力还将选择优质项目，与其共享美敦力的丰富资源，为具有市场前景的医疗创新提供临床试验、上市注册等辅导；利用公司在华生产基地，提供创新生产解决方案；开放美敦力自有的临床培训中心，支持合作企业开展相关临床技能培训。</span
                                  >
                                </p>
                                <p>
                                  <span style="color: rgb(83, 86, 90)"
                                    >上海临港经济发展（集团）有限公司旗下的浦江国际科技城，具备强大的集团背景与对政策的精准把握，美敦力创新加速器的入驻企业将有机会获得其提供的包括人力、财务、知识产权、法律、政府项目申报、投融资服务在内的基础企业服务以及股权融资、上市财务顾问及科技项目申报在内的增值服务。</span
                                  >
                                </p>
                                <p>
                                  <span style="color: rgb(83, 86, 90)"
                                    ><br
                                  /></span>
                                </p>
                                <p>
                                  <span style="color: rgb(83, 86, 90)"
                                    ><strong
                                      ><span
                                        style="
                                          color: rgb(83, 86, 90);
                                          font-size: 128.571%;
                                        "
                                        >共享价值：</span
                                      ></strong
                                    ></span
                                  >
                                </p>
                                <p>
                                  <span style="color: rgb(83, 86, 90)"
                                    >美敦力期望通过创新加速器激发医疗科技的更多潜能，与合作伙伴携手提升患者的治疗效果与医疗经济效益，为中国建设富有全球吸引力的科创中心贡献力量。</span
                                  >
                                </p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:25.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:25.col1:2.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 20%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: center;
                          margin-top: 10px;
                          margin-bottom: 10px;
                          transform: translate3d(10px, 0px, 0px);
                        "
                      >
                        <div
                          tn-cell-type="image"
                          style="
                            max-width: 100%;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: 0;
                          "
                          class="tn-cell tn-cell-image tn-state-active ng-scope"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          title=""
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col1:0.img1"
                        >
                          <img
                            class="tn-image-presenter raw-image"
                            src="//cdn.yueshare.cn/mlab/859b1d9d8e548347bde2651df0225c24-sz_13837.webp"
                            style="vertical-align: middle; max-width: 100%"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                  <div
                    style="
                      display: inline-block;
                      vertical-align: top;
                      width: 80%;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27.col2:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          margin: 50px 0% 0px;
                          transform: translate3d(30px, 0px, 0px);
                        "
                      >
                        <div
                          style="
                            text-align: justify;
                            font-size: 125%;
                            color: rgb(0, 133, 202);
                          "
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2:0.txt1"
                        >
                          <p><strong>加速</strong></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:28"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="text-align: center"
                >
                  <div
                    style="
                      display: inline-block;
                      width: 95%;
                      vertical-align: top;
                      border-width: 0px;
                      border-radius: 15px;
                      border-style: solid;
                      border-color: rgb(255, 255, 255);
                      background-color: rgb(255, 255, 255);
                      height: auto;
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:28.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:28.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:28.col1:0.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:28.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 93%;
                            vertical-align: top;
                            height: auto;
                            border-left: 3px solid rgb(255, 255, 255);
                            border-top-style: none;
                            border-bottom-style: none;
                            border-right-style: none;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:28.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:28.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  font-size: 87.5%;
                                  line-height: 1.8;
                                  letter-spacing: 2px;
                                  padding: 0px 10px;
                                  font-family: PingFangSC-light;
                                  color: rgb(83, 86, 90);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:28.col1:1.col1:0.txt1"
                              >
                                <p>
                                  <strong
                                    ><span style="font-size: 128.571%"
                                      >加速转化：</span
                                    ></strong
                                  >
                                </p>
                                <p>
                                  赋能具有较高临床与市场潜力的早期阶段医疗技术创业公司，帮助其加速从实验室到市场的创新成果转化，缩短从创新项目到成熟疗法的开发过程。
                                </p>
                                <p><br /></p>
                                <p>
                                  <strong
                                    ><span style="font-size: 128.571%"
                                      >加速应用：</span
                                    ></strong
                                  >
                                </p>
                                <p>
                                  借助美敦力注册经验和市场网络，加速先进医疗科技的落地与应用，为患者带来更多高效健康解决方案，促进本土医疗创新的可持续发展。
                                </p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:28.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:28.col1:2.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    text-align: left;
                    justify-content: flex-start;
                    margin: 10px 0%;
                    transform: translate3d(18px, 0px, 0px);
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      width: auto;
                      vertical-align: top;
                      min-width: 10%;
                      max-width: 100%;
                      height: auto;
                      box-shadow: rgb(0, 0, 0) 0px 0px 0px;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="text-align: center"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: auto;
                            vertical-align: top;
                            min-width: 10%;
                            max-width: 100%;
                            height: auto;
                            padding: 0px 10px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  color: rgb(0, 133, 202);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col1:0.col1:0.txt1"
                              >
                                <p>
                                  <strong
                                    ><span style="font-size: 125%"
                                      >延伸创新战略布局</span
                                    ></strong
                                  >
                                </p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          text-align: left;
                          justify-content: flex-start;
                          margin: 0px 0%;
                          font-size: 0%;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 100%;
                            vertical-align: top;
                            height: auto;
                            line-height: 0;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="
                              position: static;
                              transform: scale(0.8);
                              transform-origin: center center;
                              margin: 0px -33px;
                              max-width: unset !important;
                            "
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                margin: 0px 0%;
                                text-align: right;
                                justify-content: flex-end;
                                transform: translate3d(20px, 0px, 0px);
                              "
                            >
                              <div
                                tn-cell-type="shape"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-shape tn-state-active ng-scope"
                                style="
                                  background-color: rgb(0, 133, 202);
                                  height: 2px;
                                "
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col1:1.col1:0.line1"
                              ></div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:31"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: left; color: rgb(0, 133, 202)"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:31.txt1"
                  >
                    <p>
                      <strong
                        >&nbsp; &nbsp; &nbsp; 完善全创新周期的协作平台</strong
                      >
                    </p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="text-align: center"
                >
                  <div
                    style="
                      display: inline-block;
                      width: 95%;
                      vertical-align: top;
                      border-width: 0px;
                      border-radius: 15px;
                      border-style: solid;
                      border-color: rgb(255, 255, 255);
                      background-color: rgb(255, 255, 255);
                      height: auto;
                      overflow: hidden;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col1:0.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 93%;
                            vertical-align: top;
                            height: auto;
                            border-left: 3px solid rgb(255, 255, 255);
                            border-top-style: none;
                            border-bottom-style: none;
                            border-right-style: none;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  font-size: 87.5%;
                                  line-height: 1.8;
                                  letter-spacing: 2px;
                                  padding: 0px 10px;
                                  font-family: PingFangSC-light;
                                  color: rgb(83, 86, 90);
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col1:1.col1:0.txt1"
                              >
                                <p>
                                  随着美敦力创新加速器的启用，美敦力在中国的创新协作平台实现了从种子轮到成熟产品的覆盖，
                                </p>
                                <p>
                                  有机会与不同阶段的医疗技术创新企业开展协作。
                                </p>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col1:2.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:34"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:34.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="
                      display: inline-block;
                      width: 100%;
                      vertical-align: top;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          display: flex;
                          flex-flow: row nowrap;
                          margin: 5px 0% 0px;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: bottom;
                            width: auto;
                            flex: 0 0 auto;
                            align-self: flex-end;
                            min-width: 10%;
                            max-width: 100%;
                            height: auto;
                            border-bottom: 1px solid rgb(0, 133, 202);
                            border-bottom-right-radius: 0px;
                            padding: 0px 0px 5px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  vertical-align: top;
                                  background-color: rgb(185, 217, 235);
                                  border-width: 0px;
                                "
                                tn-cell-type="group"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style=""
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        color: rgb(37, 93, 152);
                                        padding: 0px 10px;
                                        letter-spacing: 1px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col1:0.col1:0.txt1"
                                    >
                                      <p>
                                        <span style="font-size: 112.5%"
                                          ><strong
                                            ><span
                                              style="color: rgb(83, 86, 90)"
                                              >美敦力创新加速器</span
                                            ></strong
                                          ><strong></strong
                                        ></span>
                                      </p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: bottom;
                            width: auto;
                            align-self: flex-end;
                            flex: 100 100 0%;
                            height: auto;
                            margin: 0px;
                            padding: 0px 0px 0px 5px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: center;
                                font-size: 0%;
                                opacity: 0.3;
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  height: 9px;
                                  vertical-align: top;
                                  overflow: hidden;
                                  background-color: rgb(0, 133, 202);
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-frozen ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:0.col2:0.col1"
                              ></div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:1"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 100%;
                            vertical-align: top;
                            border-left: 1px solid transparent;
                            border-bottom-left-radius: 0px;
                            padding: 0px 0px 0px 4px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:1.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:1.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="margin: 4px 0% 0px"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  vertical-align: top;
                                  border-left: 7px solid transparent;
                                  border-bottom-left-radius: 0px;
                                  padding: 10px 10px 20px 15px;
                                "
                                tn-cell-type="group"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:1.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style=""
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:1.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        font-size: 93.75%;
                                        color: rgb(83, 86, 90);
                                        line-height: 1.8;
                                        letter-spacing: 1.5px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:1.col1:0.col1:0.txt1"
                                    >
                                      <p>
                                        主要面向创业早期企业，帮助其加速完善产品和进行临床审批所需的准备工作。
                                      </p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:2"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:2.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="
                        position: static;
                        transform: perspective(0px);
                        transform-style: flat;
                      "
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:3"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          display: flex;
                          flex-flow: row nowrap;
                          margin: 5px 0% 0px;
                          transform: rotateY(180deg);
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: bottom;
                            width: auto;
                            flex: 0 0 auto;
                            align-self: flex-end;
                            min-width: 10%;
                            max-width: 100%;
                            height: auto;
                            border-bottom: 1px solid rgb(0, 133, 202);
                            border-bottom-right-radius: 0px;
                            padding: 0px 0px 5px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  vertical-align: top;
                                  background-color: rgb(185, 217, 235);
                                  border-width: 0px;
                                "
                                tn-cell-type="group"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="
                                    transform: perspective(0px);
                                    transform-style: flat;
                                  "
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="transform: rotateY(180deg)"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        color: rgb(37, 93, 152);
                                        padding: 0px 10px;
                                        letter-spacing: 1px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col1:0.col1:0.txt1"
                                    >
                                      <p>
                                        <span
                                          style="
                                            font-size: 112.5%;
                                            color: rgb(83, 86, 90);
                                          "
                                          ><strong>美敦力中国基金</strong></span
                                        >
                                      </p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: bottom;
                            width: auto;
                            align-self: flex-end;
                            flex: 100 100 0%;
                            height: auto;
                            margin: 0px;
                            padding: 0px 0px 0px 5px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: center;
                                font-size: 0%;
                                opacity: 0.3;
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  height: 9px;
                                  vertical-align: top;
                                  overflow: hidden;
                                  background-color: rgb(0, 133, 202);
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-frozen ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:3.col2:0.col1"
                              ></div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="
                        position: static;
                        transform: perspective(0px);
                        transform-style: flat;
                      "
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:4"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="transform: rotateY(180deg)"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 100%;
                            vertical-align: top;
                            border-left: 1px solid transparent;
                            border-bottom-left-radius: 0px;
                            padding: 0px 0px 0px 4px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:4.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:4.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="margin: 4px 0% 0px"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  vertical-align: top;
                                  border-left: 7px solid transparent;
                                  border-bottom-left-radius: 0px;
                                  padding: 10px 10px 20px 15px;
                                "
                                tn-cell-type="group"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:4.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="
                                    position: static;
                                    transform: perspective(0px);
                                    transform-style: flat;
                                  "
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:4.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                    style="transform: rotateY(180deg)"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        font-size: 93.75%;
                                        color: rgb(83, 86, 90);
                                        line-height: 1.8;
                                        letter-spacing: 1.5px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:4.col1:0.col1:0.txt1"
                                    >
                                      <p>
                                        主要面向产品进入上市准备期的企业，为其提供资金和市场策略支持。
                                      </p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style=""
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:5"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="text-align: justify"
                          tn-cell-type="text"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                          title=""
                          class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:5.txt1"
                        >
                          <p><br /></p>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:6"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="
                          display: flex;
                          flex-flow: row nowrap;
                          margin: 5px 0% 0px;
                        "
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: bottom;
                            width: auto;
                            flex: 0 0 auto;
                            align-self: flex-end;
                            min-width: 10%;
                            max-width: 100%;
                            height: auto;
                            border-bottom: 1px solid rgb(128, 182, 230);
                            border-bottom-right-radius: 0px;
                            padding: 0px 0px 5px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  vertical-align: top;
                                  background-color: transparent;
                                  border-width: 0px;
                                "
                                tn-cell-type="group"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        color: rgb(37, 93, 152);
                                        padding: 0px 10px;
                                        letter-spacing: 1px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col1:0.col1:0.txt1"
                                    >
                                      <p>
                                        <strong
                                          ><span
                                            style="
                                              color: rgb(83, 86, 90);
                                              font-size: 112.5%;
                                            "
                                            >美敦力业务发展部门</span
                                          ></strong
                                        >
                                      </p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: bottom;
                            width: auto;
                            align-self: flex-end;
                            flex: 100 100 0%;
                            height: auto;
                            margin: 0px;
                            padding: 0px 0px 0px 5px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="
                                text-align: center;
                                font-size: 0%;
                                opacity: 0.3;
                              "
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  height: 9px;
                                  vertical-align: top;
                                  overflow: hidden;
                                  background-color: rgb(0, 133, 202);
                                "
                                tn-cell-type="group"
                                tn-child-orientation="fixed"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-frozen ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:6.col2:0.col1"
                              ></div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:7"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                      >
                        <div
                          style="
                            display: inline-block;
                            width: 100%;
                            vertical-align: top;
                            border-left: 1px solid transparent;
                            border-bottom-left-radius: 0px;
                            padding: 0px 0px 0px 4px;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:7.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:7.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="margin: 4px 0% 0px"
                            >
                              <div
                                style="
                                  display: inline-block;
                                  width: 100%;
                                  vertical-align: top;
                                  border-left: 7px solid transparent;
                                  border-bottom-left-radius: 0px;
                                  padding: 10px 10px 20px 15px;
                                "
                                tn-cell-type="group"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:7.col1:0.col1"
                              >
                                <div
                                  class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                  tn-uuid=""
                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                  style="position: static"
                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:7.col1:0.col1:0"
                                >
                                  <section
                                    class="tn-comp-pin tn-comp-style-pin"
                                    tn-link="compAttr.link || comp.link"
                                  >
                                    <div
                                      style="
                                        text-align: justify;
                                        font-size: 93.75%;
                                        color: rgb(83, 86, 90);
                                        line-height: 1.8;
                                        letter-spacing: 1.5px;
                                      "
                                      tn-cell-type="text"
                                      tn-link="cell.link"
                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                      tn-animate-on-self="true"
                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                      title=""
                                      class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:7.col1:0.col1:0.txt1"
                                    >
                                      <p>
                                        主要面向具备成熟产品的企业，通过并购或代理销售为产品拓宽市场渠道。
                                      </p>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:37"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:37.txt1"
                  >
                    <p>&#8203;</p>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style="position: static"
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                  style="
                    display: flex;
                    flex-flow: row nowrap;
                    margin: 15px 0% 10px;
                  "
                >
                  <div
                    style="
                      display: inline-block;
                      width: auto;
                      vertical-align: top;
                      background-color: rgba(229, 232, 253, 0.41);
                      flex: 100 100 0%;
                      align-self: flex-start;
                      height: auto;
                      line-height: 0;
                      padding: 0px;
                    "
                    tn-cell-type="group"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1"
                  >
                    <div
                      class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                      tn-uuid=""
                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                      style="position: static"
                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:0"
                    >
                      <section
                        class="tn-comp-pin tn-comp-style-pin"
                        tn-link="compAttr.link || comp.link"
                        style="display: flex; flex-flow: row nowrap"
                      >
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            flex: 100 100 0%;
                            height: auto;
                            background-color: rgb(185, 217, 235);
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="margin: 0px 0% 8px"
                            >
                              <div
                                tn-cell-type="shape"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-shape tn-state-active ng-scope"
                                style="
                                  background-color: rgb(0, 133, 202);
                                  height: 1px;
                                "
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1:0.line1"
                              ></div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            flex: 0 0 auto;
                            align-self: flex-start;
                            min-width: 10%;
                            max-width: 100%;
                            height: auto;
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col2"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style=""
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col2:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="transform: translate3d(2px, 0px, 0px)"
                            >
                              <div
                                style="
                                  text-align: justify;
                                  font-size: 75%;
                                  color: rgb(0, 133, 202);
                                  line-height: 0;
                                  letter-spacing: 5px;
                                  padding: 0px 10px;
                                "
                                tn-cell-type="text"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                title=""
                                class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col2:0.txt1"
                              >
                                <p>END</p>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          style="
                            display: inline-block;
                            vertical-align: top;
                            width: auto;
                            flex: 100 100 0%;
                            height: auto;
                            background-color: rgb(185, 217, 235);
                          "
                          tn-cell-type="group"
                          tn-link="cell.link"
                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                          tn-animate-on-self="true"
                          class="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col3"
                        >
                          <div
                            class="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                            tn-uuid=""
                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                            style="position: static"
                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col3:0"
                          >
                            <section
                              class="tn-comp-pin tn-comp-style-pin"
                              tn-link="compAttr.link || comp.link"
                              style="margin: 0px 0% 8px"
                            >
                              <div
                                tn-cell-type="shape"
                                tn-link="cell.link"
                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                tn-animate-on-self="true"
                                class="tn-cell tn-cell-shape tn-state-active ng-scope"
                                style="
                                  background-color: rgb(0, 133, 202);
                                  height: 1px;
                                "
                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col3:0.line1"
                              ></div>
                            </section>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </div>
              <div
                class="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                tn-uuid=""
                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                style=""
                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:39"
              >
                <section
                  class="tn-comp-pin tn-comp-style-pin"
                  tn-link="compAttr.link || comp.link"
                >
                  <div
                    style="text-align: justify"
                    tn-cell-type="text"
                    tn-link="cell.link"
                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                    tn-animate-on-self="true"
                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                    title=""
                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.txt1"
                  >
                    <p><br /></p>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
        <!-- end ngRepeat: chd in cellGroupItems track by $index -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name:'about',
    mounted(){
        this.$emit("getMessage", false);
    }
}
</script>